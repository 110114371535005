import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router'
import Settings from './Settings';
import login from 'modules/Auth/Login/redux/login.reducer'
// import widget from 'modules/User/Widget/redux/widget.reducer'
import widget from 'modules/WidgetV2/redux/widget.reducer'
import api from 'modules/User/API/redux/api.reducer'
import home from 'modules/User/Home/redux/home.reducer'
import parsing from 'modules/User/Parsing/redux/parsing.reducer'

export default (history) => combineReducers({
  router: connectRouter(history),
  settings: Settings,
  login: login,
  widget: widget,
  api: api,
  home: home,
  parsing: parsing
});
