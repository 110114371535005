import {signIn, signInSuccess, signInFailed, signOut} from './login.action'
import service from './login.service'

export function handleLogin(username, password){
    return async function(dispatch){
        dispatch(signIn())
        let result = await service.login(username, password)
        if(result.status){
            console.log(result)
            service.saveToken(result.data.token)

            let level = atob(result.data.level)

            if(parseInt(level) === 0){
                dispatch(signInSuccess(true, username))
                service.saveUser(username, true)
                return '/app/admin/home'
            } else {
                dispatch(signInSuccess(false, username))
                service.saveUser(username, false)
                return '/app/user/home'
            }
        } else {
            dispatch(signInFailed(result.message))
            return null
        }
    }
}

export function handleLogout(){
    return async function(dispatch){
        service.clearAuth()
        dispatch(signOut())
        return true
    }
}

export default {handleLogin, handleLogout}
