export const actions = {
    LOAD_MASTER_API: 'LOAD_MASTER_API',
    LOAD_SUB_MASTER_API: 'LOAD_SUB_MASTER_API',
    LOAD_API_RESULT: 'LOAD_API_RESULT',
    SELECT_API_DETAIL: 'SELECT_API_DETAIL',
}

export function loadMasterApi(list){
    return {
        type: actions.LOAD_MASTER_API,
        list: list
    }
}

export function loadSubMasterApi(list){
    return {
        type: actions.LOAD_SUB_MASTER_API,
        list: list
    }
}

export function selectApiDetail(detail){
    return {
        type: actions.SELECT_API_DETAIL,
        detail: detail
    }
}

export function loadApiResult(result){
    return {
        type: actions.LOAD_API_RESULT,
        result: result
    }
}
