import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import store from './store'

const rootEl = document.getElementById('app-site');

// Create a reusable render method that we can call more than once
let render = () => {
    // Dynamically import our main App component, and render it
    const MainApp = require('./MainApp').default;
    const configuredStore = store()
    ReactDOM.render(
        <Provider store={configuredStore}>
            <MainApp />
        </Provider>,
        rootEl
    );
};

if (module.hot) {
    module.hot.accept('./MainApp', () => {
        const MainApp = require('./MainApp').default;
        render(
            <Provider store={store}>
                <MainApp />
            </Provider>,
            rootEl
        );
    });
}

render();
