import React from 'react';

const Footer = () => {
        return (
            <footer className="app-footer">
                <span className="d-inline-block">© 2020 MyVeeGO Fleet Management System | PT Astragraphia</span>
            </footer>
        );
    }
;

export default Footer;
