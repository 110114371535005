import React from "react";
import AppLayouts from "./AppLayouts";
// import Routes from "../../app/routes";
import UserRoutes from "../../modules/User"
import AdminRoutes from "../../modules/Admin"
import { useSelector } from "react-redux";
import { getUser } from 'modules/Auth/Login/redux/login.service'

const AppLayout = () => {

    const horizontalNavPosition = useSelector(({ settings }) => settings.horizontalNavPosition);
    const navigationStyle = useSelector(({ settings }) => settings.navigationStyle);
    const user = getUser()
    // Redirect if not authenticated
    if (user == null) window.location.href = '/login'

    let routes = null
    if (user.isAdmin === true) routes = <AdminRoutes />
    else routes = <UserRoutes />

    const onGetLayout = (layout) => {
        switch (layout) {
            case "inside_the_header":
                return "InsideHeaderNav";

            case "above_the_header":
                return "AboveHeaderNav";

            case "below_the_header":
                return "BelowHeaderNav";
            default: return "Vertical";
        }
    };
    const Layout = AppLayouts[navigationStyle === "vertical_navigation" ? "Vertical" : onGetLayout(horizontalNavPosition)];
    return (
        <Layout>
            {routes}
        </Layout>
    );
};

export default AppLayout;
