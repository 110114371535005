import { actions } from './api.action'

const initial = {
    master: [],
    subMaster: [],
    detail: null
}

export default function reducer(state = initial, action) {
    switch (action.type) {
        case actions.LOAD_MASTER_API:
            return {
                ...state,
                master: action.list
            }
        case actions.LOAD_SUB_MASTER_API:
            return {
                ...state,
                subMaster: action.list
            }
        case actions.SELECT_API_DETAIL:
            return {
                ...state,
                detail: action.detail
            }
        case actions.LOAD_API_RESULT:
            return {
                ...state,
                result: action.result
            }
        default:
            return state
    }
}
