export const actions = {
    LOADING_WIDGET_LIST: 'LOADING_WIDGET_LIST',
    LOAD_WIDGET_LIST: 'LOAD_WIDGET_LIST'
}

export function loadingWidgetList(){
    return {
        type: actions.LOADING_WIDGET_LIST,
        list: []
    }
}

export function loadWidgetList(list){
    return {
        type: actions.LOAD_WIDGET_LIST,
        list: list
    }
}
