import { actions } from './home.action'

const initial = {
    loading: true,
    list: []
}

export default function reducer(state = initial, action) {
    switch (action.type) {
        case actions.LOADING_WIDGET_LIST:
            return {
                loading: true
            }
        case actions.LOAD_WIDGET_LIST:
            return {
                loading: false,
                list: action.list
            }
        default:
            return state
    }
}
