import WidgetTypes from '../constants/WidgetTypes';

export const actions = {
    INITIAL_DATA: 'INITIAL_DATA',
    NEXT_PAGE: 'NEXT_PAGE',
    PREV_PAGE: 'PREV_PAGE',
    GET_WIDGET_TYPES: 'GET_WIDGET_TYPES',
    LOADING_WIDGET_LIST: 'LOADING_WIDGET_LIST',
    LOAD_WIDGET_LIST: 'LOAD_WIDGET_LIST',
    SET_STEP1_INPUT: 'SET_STEP1_INPUT',
    SET_STATUS_WIDGET_CONFIG: 'SET_STATUS_WIDGET_CONFIG',
    SET_LIST_WIDGET_CONFIG: 'SET_LIST_WIDGET_CONFIG',
    SET_STEP2_STATUS_WIDGET_INPUT: 'SET_STEP2_STATUS_WIDGET_INPUT',
    SET_STEP2_LIST_WIDGET_INPUT: 'SET_STEP2_LIST_WIDGET_INPUT',
    SET_STEP2_MAP_WIDGET_INPUT: 'SET_STEP2_MAP_WIDGET_INPUT',
    SET_STEP2_BAR_CHART_WIDGET_INPUT: 'SET_STEP2_BAR_CHART_WIDGET_INPUT',
    SET_STEP2_LINE_CHART_WIDGET_INPUT: 'SET_STEP2_LINE_CHART_WIDGET_INPUT',
    SET_STEP2_PIE_CHART_WIDGET_INPUT: 'SET_STEP2_PIE_CHART_WIDGET_INPUT',
    SET_STEP2_DONUT_CHART_WIDGET_INPUT: 'SET_STEP2_DONUT_CHART_WIDGET_INPUT',
    SET_STEP2_PANEL_WIDGET_INPUT: 'SET_STEP2_PANEL_WIDGET_INPUT',
    SET_STEP2_CCTV_WIDGET_INPUT: 'SET_STEP2_CCTV_WIDGET_INPUT',
    SET_STEP2_GAUGE_WIDGET_INPUT: 'SET_STEP2_GAUGE_WIDGET_INPUT',
    SET_STEP2_IOT_MODULE_STATUS_WIDGET_INPUT: 'SET_STEP2_IOT_MODULE_STATUS_WIDGET_INPUT',
    SET_STEP2_IOT_MODULE_HISTORY_WIDGET_INPUT: 'SET_STEP2_IOT_MODULE_HISTORY_WIDGET_INPUT',
    SET_STEP3_API: 'SET_STEP3_API',
    SET_STEP3_STATUS_WIDGET_INPUT: 'SET_STEP3_STATUS_WIDGET_INPUT',
    SET_STEP3_LIST_WIDGET_INPUT: 'SET_STEP3_LIST_WIDGET_INPUT',
    SET_STEP3_MAP_WIDGET_INPUT: 'SET_STEP3_MAP_WIDGET_INPUT',
    SET_STEP3_BAR_CHART_WIDGET_INPUT: 'SET_STEP3_BAR_CHART_WIDGET_INPUT',
    SET_STEP3_LINE_CHART_WIDGET_INPUT: 'SET_STEP3_LINE_CHART_WIDGET_INPUT',
    SET_STEP3_PIE_CHART_WIDGET_INPUT: 'SET_STEP3_PIE_CHART_WIDGET_INPUT',
    SET_STEP3_DONUT_CHART_WIDGET_INPUT: 'SET_STEP3_DONUT_CHART_WIDGET_INPUT',
    SET_STEP3_PANEL_WIDGET_INPUT: 'SET_STEP3_PANEL_WIDGET_INPUT',
    SET_STEP3_CCTV_WIDGET_INPUT: 'SET_STEP3_CCTV_WIDGET_INPUT',
    SET_STEP3_GAUGE_WIDGET_INPUT: 'SET_STEP3_GAUGE_WIDGET_INPUT',
    SET_STEP3_IOT_MODULE_STATUS_WIDGET_INPUT: 'SET_STEP3_IOT_MODULE_STATUS_WIDGET_INPUT',
    SET_STEP3_IOT_MODULE_HISTORY_WIDGET_INPUT: 'SET_STEP3_IOT_MODULE_HISTORY_WIDGET_INPUT',
    SET_WIDGET_JSON_REQUEST: 'SET_WIDGET_JSON_REQUEST',
    SET_WIDGET_RESPONSE: 'SET_WIDGET_RESPONSE',
    SET_DETAILS_CONFIG: 'SET_DETAILS_CONFIG',
    RESET_DETAILS_CONFIG: 'RESET_DETAILS_CONFIG',
    RESET_WIDGET_INPUT: 'RESET_WIDGET_INPUT',
}

export function initialData(widget){
    return {
        type: actions.INITIAL_DATA,
        page: 0,
        widget: widget
    }
}

export function nextPage(){
    return {
        type: actions.NEXT_PAGE
    }
}

export function prevPage(){
    return {
        type: actions.PREV_PAGE
    }
}

export function getWidgetTypes(list){
    return {
        type: actions.GET_WIDGET_TYPES,
        list: list
    }
}

export function validateWidgetData(data){

}

export function loadingWidgetList(){
    return {
        type: actions.LOADING_WIDGET_LIST,
        list: []
    }
}

export function loadWidgetList(list){
    return {
        type: actions.LOAD_WIDGET_LIST,
        list: list
    }
}


export function setStep1Input(inputData) {
    return {
        type: actions.SET_STEP1_INPUT,
        step1Input: inputData
    }
}

export function setStep2Input(widgetType, inputData) {
    //todo: create action function for each step2 widget type
    switch (widgetType) {
        case WidgetTypes.WIDGET_TYPE_STATUS:
            return {
                type: actions.SET_STEP2_STATUS_WIDGET_INPUT,
                step2Input: inputData
            }
        case WidgetTypes.WIDGET_TYPE_LIST:
            return {
                type: actions.SET_STEP2_LIST_WIDGET_INPUT,
                step2Input: inputData
            }
        case WidgetTypes.WIDGET_TYPE_MAP:
            return {
                type: actions.SET_STEP2_MAP_WIDGET_INPUT,
                step2Input: inputData
            }
        case WidgetTypes.WIDGET_TYPE_BAR:
            return {
                type: actions.SET_STEP2_BAR_CHART_WIDGET_INPUT,
                step2Input: inputData
            }
        case WidgetTypes.WIDGET_TYPE_LINE:
            return {
                type: actions.SET_STEP2_LINE_CHART_WIDGET_INPUT,
                step2Input: inputData
            }
        case WidgetTypes.WIDGET_TYPE_PIE:
            return {
                type: actions.SET_STEP2_PIE_CHART_WIDGET_INPUT,
                step2Input: inputData
            }
        case WidgetTypes.WIDGET_TYPE_DONUT:
            return {
                type: actions.SET_STEP2_DONUT_CHART_WIDGET_INPUT,
                step2Input: inputData
            }
        case WidgetTypes.WIDGET_TYPE_PANEL:
            return {
                type: actions.SET_STEP2_PANEL_WIDGET_INPUT,
                step2Input: inputData
            }
        case WidgetTypes.WIDGET_TYPE_CCTV:
            return {
                type: actions.SET_STEP2_CCTV_WIDGET_INPUT,
                step2Input: inputData
            }
        case WidgetTypes.WIDGET_TYPE_GAUGE:
            return {
                type: actions.SET_STEP2_GAUGE_WIDGET_INPUT,
                step2Input: inputData
            }
        case WidgetTypes.WIDGET_TYPE_IOT_MODULE_STATUS:
            return {
                type: actions.SET_STEP2_IOT_MODULE_STATUS_WIDGET_INPUT,
                step2Input: inputData
            }
        case WidgetTypes.WIDGET_TYPE_IOT_MODULE_HISTORY:
            return {
                type: actions.SET_STEP2_IOT_MODULE_HISTORY_WIDGET_INPUT,
                step2Input: inputData
            }
        default:
    }
}

export function setStep3Api(apiInput) {
    return {
        type: actions.SET_STEP3_API,
        apiInput: apiInput
    }
}

export function setStep3Input(widgetType, inputData) {
    switch (widgetType) {
        case WidgetTypes.WIDGET_TYPE_STATUS:
            return {
                type: actions.SET_STEP3_STATUS_WIDGET_INPUT,
                step3Input: inputData
            }
        case WidgetTypes.WIDGET_TYPE_LIST:
            return {
                type: actions.SET_STEP3_LIST_WIDGET_INPUT,
                step3Input: inputData
            }
        case WidgetTypes.WIDGET_TYPE_MAP:
            return {
                type: actions.SET_STEP3_MAP_WIDGET_INPUT,
                step3Input: inputData
            }
        case WidgetTypes.WIDGET_TYPE_BAR:
            return {
                type: actions.SET_STEP3_BAR_CHART_WIDGET_INPUT,
                step3Input: inputData
            }
        case WidgetTypes.WIDGET_TYPE_LINE:
            return {
                type: actions.SET_STEP3_LINE_CHART_WIDGET_INPUT,
                step3Input: inputData
            }
        case WidgetTypes.WIDGET_TYPE_PIE:
            return {
                type: actions.SET_STEP3_PIE_CHART_WIDGET_INPUT,
                step3Input: inputData
            }
        case WidgetTypes.WIDGET_TYPE_DONUT:
            return {
                type: actions.SET_STEP3_DONUT_CHART_WIDGET_INPUT,
                step3Input: inputData
            }
        case WidgetTypes.WIDGET_TYPE_PANEL:
            return {
                type: actions.SET_STEP3_PANEL_WIDGET_INPUT,
                step3Input: inputData
            }
        case WidgetTypes.WIDGET_TYPE_CCTV:
            return {
                type: actions.SET_STEP3_CCTV_WIDGET_INPUT,
                step3Input: inputData
            }
        case WidgetTypes.WIDGET_TYPE_GAUGE:
            return {
                type: actions.SET_STEP3_GAUGE_WIDGET_INPUT,
                step3Input: inputData
            }
        case WidgetTypes.WIDGET_TYPE_IOT_MODULE_STATUS:
            return {
                type: actions.SET_STEP3_IOT_MODULE_STATUS_WIDGET_INPUT,
                step3Input: inputData
            }
        case WidgetTypes.WIDGET_TYPE_IOT_MODULE_HISTORY:
            return {
                type: actions.SET_STEP3_IOT_MODULE_HISTORY_WIDGET_INPUT,
                step3Input: inputData
            }
        default:
    }
}

export function setWidgetJSONRequest(widgetJsonRequest) {
    return {
        type: actions.SET_WIDGET_JSON_REQUEST,
        widgetJsonRequest
    }
}

export function setWidgetResponse(widgetResponse) {
    return {
        type: actions.SET_WIDGET_RESPONSE,
        widgetResponse: widgetResponse
    }
}

export function setDetailsConfig(detailsConfigData) {
    return {
        type: actions.SET_DETAILS_CONFIG,
        details_config: detailsConfigData
    }
}

export function resetDetailsConfig() {
    return {
        type: actions.SET_DETAILS_CONFIG
    }
}

export function resetWidgetInput() {
    return {
        type: actions.RESET_WIDGET_INPUT
    }
}
