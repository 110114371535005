import React, {useState} from 'react';
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import creator from 'modules/Auth/Login/redux/login.creator'
import Avatar from '@material-ui/core/Avatar'
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IntlMessages from 'util/IntlMessages';
import {getUser} from 'modules/Auth/Login/redux/login.service'

const UserInfo = (props) => {

    const [anchorE1, setAnchorE1] = useState(null);
    const [open, setOpen] = useState(false);
    const history = useHistory()
    const user = getUser()

    const handleClick = event => {
        setOpen(true);
        setAnchorE1(event.currentTarget);
    };

    const handleRequestClose = async (event, menu) => {
        if(menu === 'profile') {
            // open profile page
        } else if(menu === 'setting') {
            //open setting page
        } else if(menu === 'logout'){
            //clear login data, redirect to login page
            if(await props.handleLogout()){
                history.push('/login')
            }
        }

        setOpen(false);
    };

    return (
        <div className="user-profile d-flex flex-row align-items-center">
            <Avatar
                alt='...'
                // src={"https://via.placeholder.com/150x150"}
                className="user-avatar "
            />
            <div className="user-detail">
                <h4 className="user-name" onClick={handleClick}>{user.username} <i
                    className="zmdi zmdi-caret-down zmdi-hc-fw align-middle"/>
                </h4>
            </div>
            <Menu className="user-info"
                id="simple-menu"
                anchorEl={anchorE1}
                open={open}
                onClose={(evt) => handleRequestClose(evt, null)}
                PaperProps={{
                    style: {
                        minWidth: 120,
                        paddingTop: 0,
                        paddingBottom: 0
                    }
                }}
            >
                {/* <MenuItem onClick={(evt) => handleRequestClose(evt, 'profile')}>
                    <i className="zmdi zmdi-account zmdi-hc-fw mr-2"/>
                    <IntlMessages id="popup.profile"/>
                </MenuItem>
                <MenuItem onClick={(evt) => handleRequestClose(evt, 'setting')}>
                    <i className="zmdi zmdi-settings zmdi-hc-fw mr-2"/>
                    <IntlMessages id="popup.setting"/>
                </MenuItem> */}
                <MenuItem onClick={(evt) => handleRequestClose(evt, 'logout')}>
                    <i className="zmdi zmdi-sign-in zmdi-hc-fw mr-2"/>
                    <IntlMessages id="popup.logout"/>
                </MenuItem>
            </Menu>
        </div>
    );
};

function mapDispatchToProps(dispatch){
    return {
        handleLogout: () => dispatch(creator.handleLogout())
    }
}

export default connect(null, mapDispatchToProps) (UserInfo);


