import { actions } from './parsing.action'

const initial = {
    loading: false,
    list: [],
    settingBrokerConnection: false,
    brokerConnected: false,
    console: '',
    creating: false,
    createError: null,
    editing: false,
    editError: null,
}

export default function reducer(state = initial, action) {
    switch (action.type) {
        case actions.LOAD_PARSING_LIST:
            return {
                ...state,
                loading: true
            }
        case actions.LOAD_PARSING_LIST_ERROR:
            return {
                ...state,
                loading: false,
                error: 'Failed to load'
            }
        case actions.LOAD_PARSING_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                list: action.list,
            }
        case actions.CONNECT_BROKER:
            return {
                ...state,
                settingBrokerConnection: true
            }
        case actions.CONNECT_BROKER_ERROR:
            return {
                ...state,
                settingBrokerConnection: false,
                error: 'Failed to connect to broker',
                brokerConnected: false,
            }
        case actions.CONNECT_BROKER_SUCCESS:
            return {
                ...state,
                settingBrokerConnection: false,
                brokerConnected: true,
            }
        case actions.DISCONNECT_BROKER:
            return {
                ...state,
                settingBrokerConnection: true
            }
        case actions.DISCONNECT_BROKER_SUCCESS:
            return {
                ...state,
                settingBrokerConnection: false,
                brokerConnected: false,
                console: ''
            }
        case actions.UPDATE_CONSOLE:
            return {
                ...state,
                console: action.data
            }
        case actions.CREATE_PARSING:
            return {
                ...state,
                creating: true
            }
        case actions.CREATE_PARSING_ERROR:
            return {
                ...state,
                creating: false,
                createError: 'Error'
            }
        case actions.CREATE_PARSING_SUCCESS:
            return {
                ...state,
                creating: false,
                createError: null
            }
        case actions.EDIT_PARSING:
            return {
                ...state,
                editing: true,
            }
        case actions.EDIT_PARSING_ERROR:
            return {
                ...state,
                editing: false,
                editError: 'Error'
            }
        case actions.EDIT_PARSING_SUCCESS:
            return {
                ...state,
                editing: false,
                editError: null
            }
        default:
            return state
    }
}
