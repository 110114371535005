import { actions } from './widget.action';
import { Map } from 'immutable';

export const defaultDetailConfigItem = {
    "type": "none",
    "modal_config": {
        "modal_title": "Modal Title",
        "columns": [
            {
                "header": "",
                "accessor": ""
            },
        ]
    },
    "link_url": "",
    "api": {
        "display_data": "data",
        "url": "",
        "method": "GET"
    }
};

const initial = Map({
    loading: true,
    list: [],
    types: [],
    privateWidget: [
        {value: true, label: 'Private'},
        {value: false, label: 'Public'}
    ],
    page: 0,
    connectionOptions: [
        {"label": "HTTP REST", "value": "http"},
        {"label": "Socket", "value": "socket"}
    ],
    apiInput: {
        //connection type, if none set, default to http
        connection_type: 'http',
        endpoint: '',
        socket_topic: '',
        method: 'GET',
        authTypes: [
            {value: 'myveego', label: 'MyVeego JWT'},
            {value: 'custom_auth', label: 'Custom Auth'},
            {value: 'none', label: 'None'},
        ],
        selectedAuth: 'myveego',
        auth_header: 'Authentication',
        auth_value: 'JWT ',
    },
    step1Input: {
        name: '',
        description: '',
        type: '',
        private: false
    },
    step2Input: {
        statusWidget: {
            statusTypes: [
                {label: '1 Number', value: 1},
                {label: '2 Number', value: 2},
                {label: '3 Number', value: 3},
                {label: '4 Number', value: 4}
            ],
            selectedStatusType: 1,
            selectedLabelIndex: 1,
            widgetConfig: {
                "widget_title": "Widget Title",
                "data_1_label": "",
                "data_1_color": "black",
                "data_2_label": "",
                "data_2_color": "black",
                "data_3_label": "",
                "data_3_color": "black",
                "data_4_label": "",
                "data_4_color": "black",
            }
        },
        listWidget: {
            widgetConfig: {
                columns: [
                    {header: 'Column 1', key: '', type: 'text', unit: ''},
                    {header: 'Column 2', key: '', type: 'text', unit: ''},
                    {header: 'Column 3', key: '', type: 'text', unit: ''},
                ],
                "widget_title": "Widget Title",
            }
        },
        mapWidget: {
            mapTypes: [
                {label: 'Google Maps', value: 'googlemaps'},
                {label: 'OpenStreet', value: 'openstreet'},
            ],
            widgetConfig: {
                "widget_title": "Map Title",
                "marker_icon": "",
                "map_type": "openstreet",
                "map_api_key": "",
                "group_markers": true
            }
        },
        barChartWidget: {
            widgetConfig: {
                "widget_title": "Bar Chart",
                "legend": true,
                "xaxis_title": "Label",
                "yaxis_title": "Value"
            }
        },
        lineChartWidget: {
            widgetConfig: {
                "widget_title": "Line Chart",
                "legend": true,
                "xaxis_title": "Label",
                "yaxis_title": "Value"
            }
        },
        pieChartWidget: {
            legendOptions: [
                {label: 'Top', value: 'top'},
                {label: 'Right', value: 'right'},
                {label: 'Bottom', value: 'bottom'},
                {label: 'Left', value: 'left'}
            ],
            widgetConfig: {
                "widget_title": "Pie Chart",
                "legend": true,
                "legend_position": 'bottom'
            }
        },
        donutChartWidget: {
            legendOptions: [
                {label: 'Top', value: 'top'},
                {label: 'Right', value: 'right'},
                {label: 'Bottom', value: 'bottom'},
                {label: 'Left', value: 'left'}
            ],
            widgetConfig: {
                "widget_title": "Donut Chart",
                "legend": true,
                "legend_position": 'bottom'
            }
        },
        panelWidget: {
            panelTypes: [
                {label: '1 Label', value: 'one'},
                {label: '2 Labels', value: 'two'},
            ],
            sparklineTypes: [
                {label: 'Bar', value: 'bar'},
                {label: 'Line', value: 'line'},
            ],
            widgetConfig: {
                "widget_title": "Panel Widget",
                "background_color": "#8bc34a",
                "text_color": "white",
                "sparkline": true,
                "sparkline_type": "bar",
                "panel_type": "two",
                "text_label_1": "Vehicles",
                "text_label_2": "Drivers"
            }
        },
        cctvWidget: {
            cctvOptions: [
                {label: 'HLS URL', value: 'hls_url'},
                // {label: 'RTSP API', value: 'rtsp_api'},
                {label: 'MJPEG URL', value: 'mjpeg_url'},
            ],
            widgetConfig: {
                "widget_title": "CCTV Widget",
                "cctv_type": "hls_url"
            }
        },
        gaugeWidget: {
            gaugeTypes: [
                {label: 'Radial', value: 'radial'},
                {label: 'Linear', value: 'linear'}
            ],
            widgetConfig: {
                "widget_title": "Widget Title",
                "gauge_type": 'radial', //'radial' || 'linear'
                "units": "°C",
                "title": "Temperature",
                "ticks_interval": 5, //majorTicks param
                "min_value": 0,
                "max_value": 100,
                "start_angle": 90,
                "ticks_angle": 180,
                "border_shadow_width": 0,
                "borders": true,
                "highlights": [],
                "value_box": true
            }
        },
        iotModuleStatusWidget: {
            imeiList: [''],
            widgetConfig: {
                "widget_title": "IOT Module Status Widget",
            }
        },
        iotModuleHistoryWidget: {
            imeiList: [''],
            widgetConfig: {
                "widget_title": "IOT Module History Widget",
            }
        },
    },
    step3Input: {
        statusWidget: {
            "display_data": {
                "data_1": "",
                "data_2": "",
                "data_3": "",
                "data_4": ""
            },
        },
        listWidget: {
            "display_data": ""
        },
        mapWidget: {
            "display_data": "",
            "marker_lat": "lat",
            "marker_long": "long",
            "marker_popup": {
                "title": "Title",
                "subtitle_1": "Subtitle 1",
                "subtitle_2": "Subtitle 2"
            }
        },
        barChartWidget: {
            "display_data": "data",
            "label_key": "label",
            "value_key": "value"
        },
        lineChartWidget: {
            "display_data": "data",
            "label_key": "label",
            "value_key": "value"
        },
        pieChartWidget: {
            "display_data": "data",
            "label_key": "status",
            "value_key": "value"
        },
        donutChartWidget: {
            "display_data": "data",
            "label_key": "status",
            "value_key": "value"
        },
        panelWidget: {
            "sparkline_data": "",
            "sparkline_key": "label",
            "sparkline_value": "value",
            "text_1_key": "",
            "text_2_key": ""
        },
        cctvWidget: {
            "hls_url": [''],
            "rtsp_api_url": "",
            "url_key": "value",
            "mjpeg_url": ['']
        },
        gaugeWidget: {
            "display_data": ""
        },
        iotModuleStatusWidget: {
            "devices": [
                {imei: '', url: ''}
            ]
        },
        iotModuleHistoryWidget: {
            "devices": [
                {imei: '', url: ''}
            ]
        },
    },
    actionTypes: [
        {label: 'None', value: 'none'},
        {label: 'Modal', value: 'modal'},
        {label: 'Link', value: 'link'},
    ],
    "details_config": [
        defaultDetailConfigItem
    ],

    //widget JSON request
    widgetJsonRequest: {},
    widgetResponse: {}
});

export default function wizard(state = initial, action) {
    switch (action.type) {
        case actions.INITIAL_DATA:
            return state;
        case actions.NEXT_PAGE:
            return {
                page: state.page + 1
            }
        case actions.PREV_PAGE:
            return {
                page: state.page - 1
            }
        case actions.GET_WIDGET_TYPES:
            return state.merge({
                types: action.list
            })
        case actions.LOADING_WIDGET_LIST:
            return state.merge({
                loading: true
            })
        case actions.LOAD_WIDGET_LIST:
            return state.merge({
                loading: false,
                list: action.list
            })
        case actions.SET_STEP1_INPUT:
            return state.merge({
                step1Input: action.step1Input
            })
        case actions.SET_STATUS_WIDGET_CONFIG:
            return state.merge({
                statusWidgetConfig: action.statusWidgetConfig
            })
        case actions.SET_STEP3_API:
            return state.merge({
                apiInput: action.apiInput
            })
        case actions.SET_STEP2_STATUS_WIDGET_INPUT:
            return state.merge({
                step2Input: {
                    ...state.get('step2Input'),
                    statusWidget: action.step2Input
                }
            })
        case actions.SET_STEP2_MAP_WIDGET_INPUT:
            return state.merge({
                step2Input: {
                    ...state.get('step2Input'),
                    mapWidget: action.step2Input
                }
            })
        case actions.SET_STEP2_LIST_WIDGET_INPUT:
            return state.merge({
                step2Input: {
                    ...state.get('step2Input'),
                    listWidget: action.step2Input
                }
            })
        case actions.SET_STEP2_BAR_CHART_WIDGET_INPUT:
            return state.merge({
                step2Input: {
                    ...state.get('step2Input'),
                    barChartWidget: action.step2Input
                }
            })
        case actions.SET_STEP2_LINE_CHART_WIDGET_INPUT:
            return state.merge({
                step2Input: {
                    ...state.get('step2Input'),
                    lineChartWidget: action.step2Input
                }
            })
        case actions.SET_STEP2_PIE_CHART_WIDGET_INPUT:
            return state.merge({
                step2Input: {
                    ...state.get('step2Input'),
                    pieChartWidget: action.step2Input
                }
            })
        case actions.SET_STEP2_DONUT_CHART_WIDGET_INPUT:
            return state.merge({
                step2Input: {
                    ...state.get('step2Input'),
                    donutChartWidget: action.step2Input
                }
            })
        case actions.SET_STEP2_PANEL_WIDGET_INPUT:
            return state.merge({
                step2Input: {
                    ...state.get('step2Input'),
                    panelWidget: action.step2Input
                }
            })
        case actions.SET_STEP2_CCTV_WIDGET_INPUT:
            return state.merge({
                step2Input: {
                    ...state.get('step2Input'),
                    cctvWidget: action.step2Input
                }
            })
        case actions.SET_STEP2_GAUGE_WIDGET_INPUT:
            return state.merge({
                step2Input: {
                    ...state.get('step2Input'),
                    gaugeWidget: action.step2Input
                }
            })
        case actions.SET_STEP2_IOT_MODULE_STATUS_WIDGET_INPUT:
            return state.merge({
                step2Input: {
                    ...state.get('step2Input'),
                    iotModuleStatusWidget: action.step2Input
                }
            })
        case actions.SET_STEP2_IOT_MODULE_HISTORY_WIDGET_INPUT:
            return state.merge({
                step2Input: {
                    ...state.get('step2Input'),
                    iotModuleHistoryWidget: action.step2Input
                }
            })
        case actions.SET_STEP3_LIST_WIDGET_INPUT:
            return state.merge({
                step3Input: {
                    ...state.get('step3Input'),
                    listWidget: action.step3Input
                }
            })
        case actions.SET_STEP3_STATUS_WIDGET_INPUT:
            return state.merge({
                step3Input: {
                    ...state.get('step3Input'),
                    statusWidget: action.step3Input
                }
            })
        case actions.SET_STEP3_MAP_WIDGET_INPUT:
            return state.merge({
                step3Input: {
                    ...state.get('step3Input'),
                    mapWidget: action.step3Input
                }
            })
        case actions.SET_STEP3_BAR_CHART_WIDGET_INPUT:
            return state.merge({
                step3Input: {
                    ...state.get('step3Input'),
                    barChartWidget: action.step3Input
                }
            })
        case actions.SET_STEP3_LINE_CHART_WIDGET_INPUT:
            return state.merge({
                step3Input: {
                    ...state.get('step3Input'),
                    lineChartWidget: action.step3Input
                }
            })
        case actions.SET_STEP3_PIE_CHART_WIDGET_INPUT:
            return state.merge({
                step3Input: {
                    ...state.get('step3Input'),
                    pieChartWidget: action.step3Input
                }
            })
        case actions.SET_STEP3_DONUT_CHART_WIDGET_INPUT:
            return state.merge({
                step3Input: {
                    ...state.get('step3Input'),
                    donutChartWidget: action.step3Input
                }
            })
        case actions.SET_STEP3_PANEL_WIDGET_INPUT:
            return state.merge({
                step3Input: {
                    ...state.get('step3Input'),
                    panelWidget: action.step3Input
                }
            })
        case actions.SET_STEP3_CCTV_WIDGET_INPUT:
            return state.merge({
                step3Input: {
                    ...state.get('step3Input'),
                    cctvWidget: action.step3Input
                }
            })
        case actions.SET_STEP3_GAUGE_WIDGET_INPUT:
            return state.merge({
                step3Input: {
                    ...state.get('step3Input'),
                    gaugeWidget: action.step3Input
                }
            })
        case actions.SET_STEP3_IOT_MODULE_STATUS_WIDGET_INPUT:
            return state.merge({
                step3Input: {
                    ...state.get('step3Input'),
                    iotModuleStatusWidget: action.step3Input
                }
            })
        case actions.SET_STEP3_IOT_MODULE_HISTORY_WIDGET_INPUT:
            return state.merge({
                step3Input: {
                    ...state.get('step3Input'),
                    iotModuleHistoryWidget: action.step3Input
                }
            })
        case actions.SET_WIDGET_JSON_REQUEST:
            return state.merge({
                widgetJsonRequest: action.widgetJsonRequest
            })
        case actions.SET_WIDGET_RESPONSE:
            return state.merge({
                widgetResponse: action.widgetResponse
            })
        case actions.SET_DETAILS_CONFIG:
            return state.merge({
                details_config: action.details_config
            })
        case actions.RESET_DETAILS_CONFIG:
            return state.merge({
                details_config: [JSON.parse(JSON.stringify(defaultDetailConfigItem))]
            })
        case actions.RESET_WIDGET_INPUT:
            return initial;
        default:
            return state;
    }
}
