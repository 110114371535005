import React from 'react';
import {Route, Switch, withRouter} from 'react-router-dom';
import asyncComponent from '../../util/asyncComponent';

//define routes for user module here
const UserRoutes = ({match}) =>
    <Switch>
        <Route path={`${match.url}/home`} component={asyncComponent(() => import('./Home/components/Home'))}/>

        {/* Widget Dashboard V1 - initial version */}
        <Route path={`${match.url}/widget-dashboard-v1`} component={asyncComponent(() => import('./Widget/components/WidgetDashboard'))}/>
        <Route path={`${match.url}/widget-wizard-v1`} component={asyncComponent(() => import('./Widget/components/WidgetWizard'))}/>
        {/* Widget Dashboard V2 - latest version */}
        <Route path={`${match.url}/widget-dashboard`} component={asyncComponent(() => import('../WidgetV2/components/WidgetDashboard'))}/>
        <Route path={`${match.url}/widget-wizard/:widgetId`} component={asyncComponent(() => import('../WidgetV2/components/WidgetWizard'))}/>
        <Route path={`${match.url}/widget-wizard`} component={asyncComponent(() => import('../WidgetV2/components/WidgetWizard'))}/>

        <Route path={`${match.url}/parsing-dashboard`} component={asyncComponent(() => import('./Parsing/components/ParsingDashboard'))}/>
        <Route path={`${match.url}/parsing-wizard`} component={asyncComponent(() => import('./Parsing/components/ParsingWizard'))}/>
        <Route path={`${match.url}/api-dashboard`} component={asyncComponent(() => import('./API/components/APIDashboard'))}/>
        <Route path={`${match.url}/paas`} component={asyncComponent(() => import('./Paas/components/Paas'))}/>
        <Route path={`${match.url}/manual/widget-dashboard`} component={asyncComponent(() => import('../Manual/components/WidgetDashboardManual'))}/>
        <Route path={`${match.url}/manual/mqtt-parsing`} component={asyncComponent(() => import('../Manual/components/MQTTParsingManual'))}/>
        <Route path={`${match.url}/manual/tcp-parsing`} component={asyncComponent(() => import('../Manual/components/TCPParsingManual'))}/>
        <Route path={`${match.url}/manual/api-dashboard`} component={asyncComponent(() => import('../Manual/components/ApiDashboardManual'))}/>


        {/*<Route component={asyncComponent(() => import("app/routes/extraPages/routes/404"))}/>*/}
    </Switch>;

const UserRoutesContainer = ({match}) => {
    return <Switch>
        <Route path={`${match.url}/user`} component={UserRoutes}/>
        {/*<Route component={asyncComponent(() => import("app/routes/extraPages/routes/404"))}/>*/}
    </Switch>;
}


export default withRouter(UserRoutesContainer);

