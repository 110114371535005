export const actions = {
    LOAD_PARSING_LIST: 'LOAD_PARSING_LIST',
    LOAD_PARSING_LIST_ERROR: 'LOAD_PARSING_LIST_ERROR',
    LOAD_PARSING_LIST_SUCCESS: 'LOAD_PARSING_LIST_SUCCESS',
    CONNECT_BROKER: 'CONNECT_BROKER',
    CONNECT_BROKER_ERROR: 'CONNECT_BROKER_FAILED',
    CONNECT_BROKER_SUCCESS: 'CONNECT_BROKER_SUCCESS',
    DISCONNECT_BROKER: 'DISCONNECT_BROKER',
    DISCONNECT_BROKER_ERROR: 'DISCONNECT_BROKER_FAILED',
    DISCONNECT_BROKER_SUCCESS: 'DISCONNECT_BROKER_SUCCESS',
    UPDATE_CONSOLE: 'UPDATE_CONSOLE',
    CREATE_PARSING: 'CREATE_PARSING',
    CREATE_PARSING_ERROR: 'CREATE_PARSING_ERROR',
    CREATE_PARSING_SUCCESS: 'CREATE_PARSING_SUCCESS',
    EDIT_PARSING: 'EDIT_PARSING',
    EDIT_PARSING_ERROR: 'EDIT_PARSING_ERROR',
    EDIT_PARSING_SUCCESS: 'EDIT_PARSING_SUCCESS',
}

export function loadParsingList(){
    return {
        type: actions.LOAD_PARSING_LIST
    }
}

export function loadParsingListError(){
    return {
        type: actions.LOAD_PARSING_LIST_ERROR
    }
}

export function loadParsingListSuccess(list){
    return {
        type: actions.LOAD_PARSING_LIST_SUCCESS,
        list: list,
    }
}

export function connectBroker(){
    return {
        type: actions.CONNECT_BROKER
    }
}

export function connectBrokerError(){
    return {
        type: actions.CONNECT_BROKER_ERROR
    }
}

export function connectBrokerSuccess(){
    return {
        type: actions.CONNECT_BROKER_SUCCESS
    }
}

export function disconnectBroker(){
    return {
        type: actions.DISCONNECT_BROKER
    }
}

export function disconnectBrokerFailed(){
    return {
        type: actions.DISCONNECT_BROKER_ERROR
    }
}

export function disconnectBrokerSuccess(){
    return {
        type: actions.DISCONNECT_BROKER_SUCCESS
    }
}

export function updateConsole(data){
    return {
        type: actions.UPDATE_CONSOLE,
        data: data
    }
}

export function createParsing(){
    return {
        type: actions.CREATE_PARSING
    }
}

export function createParsingError(){
    return {
        type: actions.CREATE_PARSING_ERROR
    }
}

export function createParsingSuccess(){
    return {
        type: actions.CREATE_PARSING_SUCCESS
    }
}

export function editParsing(){
    return {
        type: actions.EDIT_PARSING
    }
}

export function editParsingError(){
    return {
        type: actions.EDIT_PARSING_ERROR
    }
}

export function editParsingSuccess(){
    return {
        type: actions.EDIT_PARSING_SUCCESS
    }
}
