// widget type IDs as defined in pp_widget_types table
export default {
    WIDGET_TYPE_LIST: 1,
    WIDGET_TYPE_STATUS: 2,
    WIDGET_TYPE_MAP: 3,
    WIDGET_TYPE_PIE: 4,
    WIDGET_TYPE_BAR: 5,
    WIDGET_TYPE_LINE: 6,
    WIDGET_TYPE_DONUT: 7,
    WIDGET_TYPE_PANEL: 8,
    WIDGET_TYPE_CCTV: 9,
    WIDGET_TYPE_GAUGE: 10,
    WIDGET_TYPE_IOT_MODULE_STATUS: 11,
    WIDGET_TYPE_IOT_MODULE_HISTORY: 12,
}
