import hmacSHA512 from 'crypto-js/hmac-sha512'
import Base64 from 'crypto-js/enc-base64'

const key = 'MyV3eG0P4r7t3RpL4tF0rM'

function handleResponse(response) {
    return response.json()
}

function login(username, password) {
    const url = `${process.env.REACT_APP_AUTH_HOSTNAME}/login/web`
    const options = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json; charset=UTF-8'
        },
        body: JSON.stringify({
            username: username,
            password: password,
            user: 1
        })
    }
    return fetch(url, options).then(handleResponse)
}

export async function saveToken(token) {
    await localStorage.setItem('token', token);
    let tokenSplit = token.split('.');

    if (tokenSplit.length > 1) {
        let payload = JSON.parse(atob(tokenSplit[1]));
        if (payload.id) {
            await localStorage.setItem('user_id', payload.id);
        }
    }
}

export function getToken() {
    return localStorage.getItem('token')
}

export async function saveUser(username, isAdmin) {
    let encryptedIsAdmin = Base64.stringify(hmacSHA512(isAdmin, key))

    await localStorage.setItem('user_data', JSON.stringify({
        username: username,
        status: encryptedIsAdmin
    }))
}

export function getUser() {
    let user = localStorage.getItem('user_data')
    if (!user) return null

    // return {
    //     username: user.username,
    //     isAdmin: true
    // } //temporary

    user = JSON.parse(user)
    let check = Base64.stringify(hmacSHA512(true, key))
    if (user.status === check) {
        user = {
            username: user.username,
            isAdmin: true
        }
    } else {
        user = {
            username: user.username,
            isAdmin: false
        }
    }
    return user
}

export function clearAuth() {
    localStorage.removeItem('token')
    localStorage.removeItem('user_id')
    localStorage.removeItem('user_data')
}

export default { login, saveToken, getToken, saveUser, clearAuth }
