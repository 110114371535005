export const actions = {
    SIGN_IN: 'SIGN_IN',
    SIGN_IN_SUCCESS: 'SIGN_IN_SUCCESS',
    SIGN_IN_FAILED: 'SIGN_IN_FAILED',
    SIGN_OUT: 'SIGN_OUT'
}

export function signIn(){
    return {
        type: actions.SIGN_IN
    }
}

export function signInSuccess(isAdmin, name){
    return {
        type: actions.SIGN_IN_SUCCESS,
        isAdmin: isAdmin,
        name: name
    }
}

export function signInFailed(error){
    return {
        type: actions.SIGN_IN_FAILED,
        error: error
    }
}

export function signOut(){
    return {
        type: actions.SIGN_OUT,
        isAdmin: null,
        name: null
    }
}
