import React from 'react';

import CustomScrollbars from 'util/CustomScrollbars';
import Navigation from "../../components/Navigation";
import { getUser } from 'modules/Auth/Login/redux/login.service'

const SideBarContent = () => {
    const user = getUser()
    let menus = []

    if (user.isAdmin) {
        menus = [
            {
                name: 'Admin Dashboard',
                type: 'item',
                link: '/app/admin/admin-dashboard',
                icon: 'view-dashboard'
            },
            {
                name: 'Widget Dashboard',
                type: 'item',
                link: '/app/admin/widget-dashboard',
                icon: 'view-dashboard'
            },
            {
                name: 'MQTT Parsing',
                type: 'item',
                link: '/app/admin/parsing-dashboard',
                icon: 'view-dashboard'
            },
            {
                name: 'TCP Parsing',
                type: 'item',
                link: '/app/admin/paas',
                icon: 'view-dashboard'
            },
            {
                name: 'API Dashboard',
                type: 'item',
                link: '/app/admin/api-dashboard',
                icon: 'view-dashboard'
            },
            {
                name: 'Mock API',
                type: 'item',
                link: '/app/admin/mock',
                icon: 'view-dashboard'
            },
            {
                name: 'API Key',
                type: 'item',
                link: '/app/admin/api-key',
                icon: 'view-dashboard'
            },
            {
                name: 'MQTT Vehicle Simulator',
                type: 'item',
                link: '/app/admin/mqtt-vehicle-emulator',
                icon: 'view-dashboard'
            },
            {
                name: 'User Manual',
                type: 'section',
                children: [
                    {
                        name: 'Widget Dashboard',
                        type: 'item',
                        link: '/app/admin/manual/widget-dashboard',
                        icon: 'view-dashboard'
                    },
                    {
                        name: 'MQTT Parsing',
                        type: 'item',
                        link: '/app/admin/manual/mqtt-parsing',
                        icon: 'view-dashboard'
                    },
                    {
                        name: 'TCP Parsing',
                        type: 'item',
                        link: '/app/admin/manual/tcp-parsing',
                        icon: 'view-dashboard'
                    },
                    {
                        name: 'Widget Dashboard',
                        type: 'item',
                        link: '/app/admin/manual/widget-dashboard',
                        icon: 'view-dashboard'
                    },
                    {
                        name: 'API Dashboard',
                        type: 'item',
                        link: '/app/admin/manual/api-dashboard',
                        icon: 'view-dashboard'
                    },
                ]
            },
        ]
    } else {
        menus = [
            {
                name: 'pages.user.home',
                type: 'item',
                link: '/app/user/home',
                icon: 'home'
            },
            {
                name: 'Widget Dashboard',
                type: 'item',
                link: '/app/user/widget-dashboard',
                icon: 'view-dashboard'
            },
            {
                name: 'MQTT Parsing',
                type: 'item',
                link: '/app/user/parsing-dashboard',
                icon: 'view-dashboard'
            },
            {
                name: 'TCP Parsing',
                type: 'item',
                link: '/app/user/paas',
                icon: 'view-dashboard'
            },
            {
                name: 'API Dashboard',
                type: 'item',
                link: '/app/user/api-dashboard',
                icon: 'view-dashboard'
            },
            {
                name: 'User Manual',
                type: 'section',
                children: [
                    {
                        name: 'Widget Dashboard',
                        type: 'item',
                        link: '/app/user/manual/widget-dashboard',
                        icon: 'view-dashboard'
                    },
                    {
                        name: 'MQTT Parsing',
                        type: 'item',
                        link: '/app/user/manual/mqtt-parsing',
                        icon: 'view-dashboard'
                    },
                    {
                        name: 'TCP Parsing',
                        type: 'item',
                        link: '/app/user/manual/tcp-parsing',
                        icon: 'view-dashboard'
                    },
                    {
                        name: 'Widget Dashboard',
                        type: 'item',
                        link: '/app/user/manual/widget-dashboard',
                        icon: 'view-dashboard'
                    },
                    {
                        name: 'API Dashboard',
                        type: 'item',
                        link: '/app/user/manual/api-dashboard',
                        icon: 'view-dashboard'
                    },
                ]
            },
        ]
    }

    const navigationMenus = [
        {
            name: 'sidebar.main',
            type: 'section',
            children: menus
        },
    ];

    return (
        <CustomScrollbars className=" scrollbar">
            <Navigation menuItems={navigationMenus} />
        </CustomScrollbars>
    );
};

export default SideBarContent;
