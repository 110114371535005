import React from 'react'
import { Route, Switch, withRouter, Redirect } from 'react-router-dom'
import asyncComponent from '../../util/asyncComponent'
import {getUser} from 'modules/Auth/Login/redux/login.service'

const LoginRoutes = ({ match }) => {

    const user = getUser()
    if(user != null) {
        if(user.isAdmin) return (<Redirect to="app/admin/home"/>)
        else return (<Redirect to="app/user/home"/>)
    }

    return <Switch>
        <Route exact path={`/login`} component={asyncComponent(() => import('./Login/components/Login'))} />
    </Switch>
}


export default withRouter(LoginRoutes)
