import { actions } from './login.action'

export default function login(state = {}, action){
    switch(action.type){
        case actions.SIGN_IN:
            return {
                loading: true
            }
        case actions.SIGN_IN_SUCCESS:
            return {
                loading: false,
                success: true,
                isAdmin: action.isAdmin,
                name: action.name
            }
        case actions.SIGN_IN_FAILED:
            return {
                loading: false,
                success: false,
                error: action.error
            }
        default:
            return state
    }
}
